.reaction-container {
  max-width: 100%;
  /* margin:150px auto; */
  /* padding:0 60px; */
  position: relative;
}
.reaction-btn {
  font-weight: bold;
  color: #7f7f7f;
  position: relative;
  cursor: pointer;
  padding: 20px 20px 0 0;
}
.reaction-btn:hover {
  text-decoration: underline;
}
.like-btn-default {
  background-repeat: no-repeat;
  background-size: auto;
  background-position: -277px -446px;
}
.reaction-btn-emo {
  display: inline-block;
  margin: 0 6px -3px 0;
  width: 16px;
  height: 16px;
}
.emojies-box {
  height: 48px;
  width: 280px;
  padding: 4px !important;
  position: absolute !important;
  top: -50px;
  left: 20px;
  box-shadow: 1px 1px 2px #cccccc, -1px 0px 2px #eeeeee;
  border-radius: 44px 44px;
  display: none;
}
.emoji {
  list-style-type: none;
  cursor: pointer;
  display: inline-block;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 8px;
  opacity: 0;
  transform: scale(1, 1);
  transition: opacity 0.5s ease-in-out 1s, transform 0.07s ease-in-out 0s,
    top 0.07s ease-in-out 0s;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.reaction-btn:hover .emojies-box {
  display: block;
}
.emo-like {
  left: 5px;
  transition-delay: 0s;
  background-image: url("../images/reactions_like.png");
}
.emo-love {
  left: 10px;
  transition-delay: 0.05s;
  background-image: url("../images/reactions_love.png");
}
.emo-haha {
  left: 15px;
  transition-delay: 0.1s;
  background-image: url("../images/reactions_haha.png");
}
.emo-wow {
  left: 20px;
  transition-delay: 0.15s;
  background-image: url("../images/reactions_wow.png");
}
.emo-sad {
  left: 25px;
  transition-delay: 0.2s;
  background-image: url("../images/reactions_sad.png");
}
.emo-angry {
  left: 30px;
  transition-delay: 0.25s;
  background-image: url("../images/reactions_angry.png");
}
.reaction-btn:hover .emoji {
  opacity: 1;
  animation-name: reaction_delay;
  animation-duration: 0.5s;
}
@keyframes reaction_delay {
  0% {
    width: 48px;
    height: 48px;
    top: 60px;
  }
  48% {
    width: 56px;
    height: 56px;
    top: 5px;
  }
  100% {
    width: 48px;
    height: 48px;
    top: 8px;
  }
}

.reaction-btn:hover .emo-like {
  animation-delay: 0s;
}

.reaction-btn:hover .emo-love {
  animation-delay: 0.05s;
}

.reaction-btn:hover .emo-haha {
  animation-delay: 0.1s;
}

.reaction-btn:hover .emo-wow {
  animation-delay: 0.15s;
}

.reaction-btn:hover .emo-sad {
  animation-delay: 0.2s;
}

.reaction-btn:hover .emo-angry {
  animation-delay: 0.25s;
}

.emoji:hover {
  transform: scale(1.3, 1.3);
  top: 2px;
}

.emoji::before {
  display: inline-block;
  color: #ffffff;
  text-align: center;
  line-height: 17px;
  font-size: 0.7em;
  width: 90%;
  height: 17px;
  margin-left: 10%;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 20px;
  position: absolute;
  top: -25px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out 0s;
}

.emoji:hover::before {
  opacity: 1;
}

.emo-like::before {
  content: "Like";
}

.emo-love::before {
  content: "Love";
}

.emo-haha::before {
  content: "Haha";
}

.emo-wow::before {
  content: "Wow";
}

.emo-sad::before {
  content: "Sad";
}

.emo-angry::before {
  content: "Angry";
}

.like-stat {
  margin-top: 10px;
}

.like-btn-like {
  background-image: url("../images/reaction-small.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: -17px -151px;
}

.like-btn-love {
  background-image: url("../images/reaction-small.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 0 -168px;
}

.like-btn-haha {
  background-image: url("../images/reaction-small.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 0 -151px;
}

.like-btn-wow {
  background-image: url("../images/reaction-small.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: -17px -185px;
}

.like-btn-sad {
  background-image: url("../images/reaction-small.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: -17px -168px;
}

.like-btn-angry {
  background-image: url("../images/reaction-small.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: -17px -117px;
}

.reaction-btn-text {
  margin-left: -4px !important;
}

.reaction-btn-text-like {
  color: rgb(88, 144, 255);
}
.reaction-btn-text-wow,
.reaction-btn-text-haha,
.reaction-btn-text-sad {
  color: rgb(240, 186, 21);
}
.reaction-btn-text-love {
  color: rgb(242, 82, 104);
}
.reaction-btn-text-angry {
  color: rgb(247, 113, 75);
}

.like-emo > span {
  display: inline-block;
  margin: 0 -3px -3px 0px;
  width: 16px;
  height: 16px;
  border: 1px solid #eee;
  border-radius: 50%;
}

.like-details {
  margin-left: 10px;
  color: #9197a3;
  font-size: 12px;
}
