/*
  Note: The content of this file must be manually minified and added to
  f7.welcomescreen.js and assign the result to the variable "css".
*/
.welcomescreen-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 10500;
  color: #666;
  font-size: 16px;
}

.welcomescreen-closebtn{
  top: 5px;
  right: 5px;
  position: absolute;
  text-align: right;
  z-index: 9998;
  font-size: 14px;
  padding: 15px;
  cursor: pointer;
}

.welcomescreen-swiper {
  position:relative;
  width: 100%;
  height: 100%;
}

.welcomescreen-title{
  width: 100%;
  text-align: center;
  margin-top: 20%;
}

.welcomescreen-picture{
  width: 100%;
  text-align: center;
  margin-top: 20%;
}

.welcomescreen-text {
  position:absolute;
  bottom: 65px;
  left: 0;
  right: 0;
  padding-left: 20px;
  padding-right: 20px;
  text-align:center;
}
.welcomescreen-content {
  padding: 15px;
}

.welcomescreen-container .swiper-pagination-bullet{
  background: rgba(255, 255, 255, 0.9) !important;
}

.welcomescreen-container .swiper-pagination-bullet-active{
  background: #fff;
}

.swiper-container-horizontal>.swiper-pagination{
  bottom: 20px;
}

html.with-statusbar-overlay .welcomescreen-container{
    top: 20px;
}

/*
    Parallax Background Effect
*/
.parallax-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 130%;
    height: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
}

.hide-title {
    opacity: 0;
}