.peg,
.wheelSVG {
    visibility: hidden;
}
.centerCircle,
.valueContainer,
.wheelOutline,
.wheelText {
    pointer-events: none;
}
#container button {
    position: relative;
    background-color: #E81D62;
}
.wheelContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /*width: 100vw;
    height: 100vw;*/
    margin: 0;
}
.wheelSVG {
    position: relative;
    overflow: visible;
    /*width: 100vw;
    height: 100vw;*/
}
.wheelText {
    text-anchor: start;
    font-family: Montserrat, sans-serif;
    -webkit-user-select: none;
    user-select: none;
}
.wheelText tspan {
    text-anchor: middle;
    letter-spacing: 2px;
}
.toast {
    border-radius: 12px;
    opacity: 0;
    background-color: #E81D62;
    position: absolute;
    overflow: hidden;
    color: #FFF;
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    width: 200px !important;
    padding: 10px 20px;
    max-width: 600px;
    left: 0px !important;
    margin-left: 96px !important;
}
.toast p {
    clear: both;
    font-family: Montserrat, Arial, sand-serif;
    margin: 0px;
    font-size: 20px;
    color: #ededed;
    letter-spacing: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 32px;
    -webkit-transition: line-height .2s ease;
    transition: line-height .2s ease;
}
#container button {
    position: relative;
    width: 92vw;
    /* top: 20px; */
    max-width: 400px;
    padding: 20px;
    font-weight: 700;
    font-size: 2rem;
    color: #ededed;
    border-radius: 6px;
    border: none;
    box-shadow: 0 2px 0 #D71559;
    cursor: pointer;
    font-family: Montserrat, sans-serif;
}

/*@media only screen and (max-width: 480px) {
    button {
        font-size: 1rem;
        padding: 10px;
        width: 60vw;
    }   
    .toast {
		padding: 0;
    }
    .toast p,
    .toast span {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}

@media only screen and (min-width: 481px) and (max-width: 800px) {

    .toast {
		padding: 0;
    }    
    .toast p,
    .toast span {
        font-size: 1.2rem;
        line-height: 2rem;
    }
}
@media only screen and (min-width: 801px) {
    .toast p,
    .toast span {
        font-size: 24px;
        line-height: 2rem;
    }
}
@media only screen and (max-height: 480px) {
    .toast p,
    .toast span {
        font-size: 16px;
        line-height: 26px;
    }
    button {
        font-size: 22px;
        padding: 10px;
        width: 90%;
    }
}
button:focus {
    outline: 0;
}*/
