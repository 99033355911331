@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
.botui-container {
  font-size: 14px;
  background-color: #fff;
  font-family: "Open Sans", sans-serif;
}
.botui-messages-container {
  padding: 10px 20px;
}
.botui-actions-container {
  padding: 10px 20px;
}
.botui-message {
  min-height: 30px;
}
.botui-message-content {
  padding: 7px 13px;
  border-radius: 15px;
  color: #595a5a;
  background-color: #ebebeb;
}
.botui-message-content.human {
  color: #f7f8f8;
  background-color: #919292;
}
.botui-message-content.text {
  line-height: 1.3;
}
.botui-message-content.loading {
  background-color: rgba(206, 206, 206, 0.5);
  line-height: 1.3;
  text-align: center;
}
.botui-message-content.embed {
  padding: 5px;
  border-radius: 5px;
}
.botui-message-content-link {
  color: #919292;
}
.botui-actions-text-input {
  border: 0;
  outline: 0;
  border-radius: 0;
  padding: 5px 7px;
  font-family: "Open Sans", sans-serif;
  background-color: transparent;
  color: #595a5a;
  border-bottom: 1px solid #919292;
}
.botui-actions-text-submit {
  color: #fff;
  width: 30px;
  padding: 5px;
  height: 30px;
  line-height: 1;
  border-radius: 50%;
  border: 1px solid #919292;
  background: #777979;
}
.botui-actions-buttons-button {
  border: 0;
  color: #fff;
  line-height: 1;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 7px 15px;
  border-radius: 4px;
  font-family: "Open Sans", sans-serif;
  background: #777979;
  box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.25);
}
.botui-actions-text-select {
  border: 0;
  outline: 0;
  border-radius: 0;
  padding: 5px 7px;
  font-family: "Open Sans", sans-serif;
  background-color: transparent;
  color: #595a5a;
  border-bottom: 1px solid #919292;
}
.botui-actions-text-searchselect {
  border: 0;
  outline: 0;
  border-radius: 0;
  padding: 5px 7px;
  font-family: "Open Sans", sans-serif;
  background-color: transparent;
  color: #595a5a;
  border-bottom: 1px solid #919292;
}
.botui-actions-text-searchselect .dropdown-toggle {
  border: none !important;
}
.botui-actions-text-searchselect .selected-tag {
  background-color: transparent !important;
  border: 0 !important;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(-10px);
}
.dot {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.5rem;
  display: inline-block;
  background-color: #919292;
}
.dot:nth-last-child(1) {
  margin-left: 0.3rem;
  animation: loading 0.6s 0.3s linear infinite;
}
.dot:nth-last-child(2) {
  margin-left: 0.3rem;
  animation: loading 0.6s 0.2s linear infinite;
}
.dot:nth-last-child(3) {
  animation: loading 0.6s 0.1s linear infinite;
}
@keyframes loading {
  0% {
    transform: translate(0, 0);
    background-color: #ababab;
  }
  25% {
    transform: translate(0, -3px);
  }
  50% {
    transform: translate(0, 0);
    background-color: #ababab;
  }
  75% {
    transform: translate(0, 3px);
  }
  100% {
    transform: translate(0, 0);
  }
}
